import { useAnalytics } from '@/composables/useAnalytics'

export function useProbanceAnalytics() {
  const { $currentUser, $cart } = useNuxtApp()
  // const config = useRuntimeConfig()

  const sendTagEvent = () => {}

  const sendEvent = (name, params) => {
    const { events } = useAnalytics()
    if (name === events.ADD_TO_CART) {
      // customization part
      var typeID = $currentUser().isAuthenticated ? 'email' : '' // TO FILL WITH THE KIND OF VISITOR IDENTIFIER (set here 'email', or 'customer_id', or ...)
      var my_id = $currentUser().isAuthenticated ? $currentUser()?.email : '' // TO FILL WITH THE VISITOR IDENTIFIER VALUE (set the value of the visitor's email, or his customer_id value, ...)
      var pid = params?.probance?.item_id || '' // TO FILL WITH THE VALUE OF THE PRODUCT IDENTIFIER (get it from your page/database via ajax for example)
      var aid = '' // TO FILL WITH THE ARTICLE IDENTIFIER (PRODUCT DECLENSION) IF YOU CAN GET IT (When this concept exists on your website)
      var bid = $cart.cartUid || '' // TO FILL WITH THE VALUE OF THE CART IDENTIFIER (get it from your page/database via ajax for example)
      var url = document.location.href //CATCH THE CURRENT URL (you can set a specific and valid url if you want)
      // if (config.public.NODE_ENV !== 'production') {
      //   console.log('sendEvent Probance AddToCart', url, typeID, my_id, pid, bid)
      // }
      // end of customization part
      var crm = null
      try {
        // eslint-disable-next-line no-undef
        crm = new PROBANCE_CRMTracker('Kcaz6MIFWVD9zrT', 'Prob_Track', 90, '//t4.my-probance.one/webtrax', 'idprob')
      } catch (err) {
        return
      }
      if (crm == null) {
        return
      }

      // To call Probance tracker
      // eslint-disable-next-line no-undef
      var PROBANCE_tracker = new PROBANCE_trackers()
      PROBANCE_tracker.setCRM(crm)

      //To save your visitor identifier (declared in previous variables) if you've it
      if (my_id != null && my_id != undefined && my_id != '') {
        crm.setCustomer(my_id, typeID)
      }
      PROBANCE_tracker.doNotTrackOnInit()
      PROBANCE_tracker.init()

      // Tracking the add to cart
      if (pid == null || pid == undefined) {
        pid = ''
      }
      if (aid == null || aid == undefined) {
        aid = ''
      }
      if (bid == null || bid == undefined) {
        bid = ''
      }

      PROBANCE_tracker.trackEvent(url, [
        ['product_id', pid],
        ['article_id', aid],
        ['basket_id', bid],
        ['action', 'cart'],
      ])
    }
  }

  const sendScreen = (name, params = {}) => {
    // customization part
    var typeID = $currentUser().isAuthenticated ? 'email' : '' // TO FILL WITH THE KIND OF VISITOR IDENTIFIER (set here 'email', or 'customer_id', or ...)
    var my_id = $currentUser().isAuthenticated ? $currentUser()?.email : '' // TO FILL WITH THE VISITOR IDENTIFIER VALUE (set the value of the visitor's email, or his customer_id value, ...)
    var pid = params?.probance?.item_id || '' // TO FILL WITH THE PRODUCT IDENTIFIER IF THE CURRENT PAGE IS A PRODUCT PAGE (Get the element in your page/database via ajax)
    var aid = '' // TO FILL WITH THE ARTICLE IDENTIFIER (PRODUCT DECLENSION) IF YOU CAN GET IT (When this concept exists on your website)
    var url = document.location.href //CATCH THE CURRENT URL (you can set a specific and valid url if you want)
    // if (config.public.NODE_ENV !== 'production') {
    //   console.log('sendScreen Probance', url, typeID, my_id, pid)
    // }
    // end of customization part

    var crm = null
    try {
      // eslint-disable-next-line no-undef
      crm = new PROBANCE_CRMTracker('Kcaz6MIFWVD9zrT', 'Prob_Track', 90, '//t4.my-probance.one/webtrax', 'idprob')
    } catch (err) {
      return
    }
    if (crm == null) {
      return
    }

    // To call Probance tracker
    // eslint-disable-next-line no-undef
    var PROBANCE_tracker = new PROBANCE_trackers()
    PROBANCE_tracker.setCRM(crm)

    //To save your visitor identifier (declared in previous variables) if you've it
    if (my_id != null && my_id != undefined && my_id != '') {
      crm.setCustomer(my_id, typeID)
    }
    PROBANCE_tracker.doNotTrackOnInit()
    PROBANCE_tracker.init()

    // Tracking for Visit
    if (pid == null || pid == undefined) {
      pid = ''
    }
    if (aid == null || aid == undefined) {
      aid = ''
    }
    PROBANCE_tracker.track(url, [
      ['product_id', pid],
      ['article_id', aid],
      ['action', 'visit'],
    ])

    //=========================================================================
  }
  return {
    sendTagEvent,
    sendEvent,
    sendScreen,
  }
}
